
/* login page background*/
.bg {
    position:fixed;
    padding:0;
    margin:0;
  
    top:0;
    left:0;
  
    width: 100%;
    height: 100%;
    background-image: url('../../public/images/EmbodyBio+Squarespace+Bkgnd.png');
    background-size:cover;
  }
  
    