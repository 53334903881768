
.contentTabs {
	position: absolute;
	top: 4px;
	left: 12px;
	bottom: 0px;
	width: 100%;
}

.tabControl {
	width: 30px;
	color: gray;
	padding: 8px;
}

