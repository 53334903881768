.resourceHeader {
	text-align: center;
	padding: 2px;
	border-top: 1px solid grey;
	border-bottom: 1px solid grey;
}


.rhPlusBtn {
	position: absolute;
	right: 0px;
	padding: 0px;
	opacity: 0.1;
	z-index: 10;
	transition: opacity 0.2s linear;
	font-size: small;
}

.rhPlusBtn:hover { 
	visibility:visible;
	opacity: 1;
	font-weight:bold;
}

.resourceList {
 	margin: 0px;
	padding: 0px;
	list-style: none;
	position: absolute;
	width: 100%;
	top: 28px;
	bottom: 0;
	overflow: auto;
}

.resourceList li {
	list-style: none;	
 	margin: 0px;
 	padding: 1px;
    font-size:12px;
}

.resourceList li:hover, .resourceList li:focus {
 	padding: 0px;
    border: 1px solid #C0C0C0;
}

.resourceItem {
 	padding: 2px 4px 2px 4px;
 	border-bottom: 1px solid lightgray;
}

.resourceSelected {
	background-color:#E0E0E0;
    border: 1px solid #ddd;
}

.resourceTitle {
 	margin: 0px 0px 4px 0px;
    font-size:14px;
}

.resourceSelected > .resourceTitle {
	font-weight:600;
}

/* display for selected id or tag filter*/
.filterText {
	position: absolute;
	top:28px;
	font-size:12px;
	margin:4px;
}

.filterCancel {
	position: absolute;
	right: 4px;
	margin: 3px;
}

.filterCancel:hover {
	padding: 0px;
   border: 1px solid #C0C0C0;
}

/* css for document references */

.mdDocTitle {
    font-size: 14px;
}

.docNote {
    margin: 6px;
}

.docEmpty {
    padding: 6px;
    background-color: #fff5ad;
    border-radius: 8px;
    border: 1px solid #C0C0C0;
}

.docTitle {
    font-size: 14px;
}

.docText {
	margin: 0px;
    font-size: 12px;
}

.authorText {
	margin: 0px;
    font-size: 12px;
    font-style:italic;
}


.noteTop {
    padding: 6px;
    background-color: #fff5ad;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #C0C0C0;
}

.noteBottom {
    padding: 6px;
    margin: -6px;
	top: 6px;
    font-size: 11px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #C0C0C0;
}

.noteColor {
    background-color: #fff5ad;
}

.noteSelected {
	border: 1px solid black;
}

.reflink {
	font-size: 18px;
	font-weight: bold;
}

.reflink:hover {
    border: 2px solid darkblue;
	border-radius: 16px;
}
