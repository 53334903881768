
.navbar {
  background-color: #3fb6ff;
	border-bottom: 1px solid #C0C0C0;
  padding: 0px;
  margin: 0px;
}

.tabControl {
	width: 30px;
	color: gray;
	padding: 8px;
}

.dropdownItemChecked::before {
	position: absolute;
	left: .2rem;
	content: '✓';
	font-weight: 600;
}

#drop-area {
	border: 2px dashed #ccc;
	border-radius: 10px;
	width: 100%;
	font-family: sans-serif;
	margin: 10px auto;
	padding: 10px;
}

#drop-area.highlight {
	border-color: purple;
}


#gallery {
	margin-top: 10px;
}

#gallery img {
	width: 150px;
	margin-bottom: 10px;
	margin-right: 10px;
	vertical-align: middle;
}

.button {
	display: inline-block;
	padding: 10px;
	background: #ccc;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #ccc;
}

.button:hover {
	background: #ddd;
}

#fileElem {
	display: none;
}
